<template>
    <div class="tag-container">

        <div class="container-header">
            <h3>{{ title }}</h3>
            <div class="chevron" @click="handleOpen()" v-bind:class="{ rotateUp: !open, rotateDown: open }">
                <ChevronUp />
            </div>
        </div>

        <div v-if="this.open" v-bind:class="{ openTable: open, closeTable: !open }">
            <p>Selecionados: </p>
            <div class="tags">
                <span v-for="tag in tags" :key="tag">{{ tag }}</span>
            </div>
        </div>
    </div>

</template>

<script>
import ChevronUp from '@/assets/icons/chevron-up.svg'

export default {
    components: { ChevronUp },

    props : {
        title: String,
        tags: [],
    },

    data() {
      return{
        open: true,
      }
    },

    methods: {
      handleOpen(){
        this.open = !this.open
      }
    },
}
</script>

<style lang="scss" scoped>
    .tag-container {
        display: flex;
        flex-direction: column;
        margin: 20px 0;

        .container-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-right: 30px;

            h3 {
            font-size: 18px;
            color: var(--dark-blue);
            font-weight: 600;
            }

            .chevron {
            width: 25px;
            height: 25px;

            > svg {
                cursor: pointer;
                stroke: var(--neutral-600);
                transition: .5s;
            }
            }

            .rotateUp {
                animation: rotateUp .5s;
                -webkit-animation-fill-mode: forwards;
                -moz-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
            }

            .rotateDown {
                animation: rotateDown .5s;
                -webkit-animation-fill-mode: forwards;
                -moz-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
            }

            @keyframes rotateUp {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(180deg);
            }
            }

            @keyframes rotateDown {
            0% {
                transform: rotate(180deg);
            }
            100% {
                transform: rotate(0deg);
            }
            }

        }


        p {
            font-size: 14px;
            font-weight: 700;
            color: var(--type-active);
            margin-bottom: 10px;
        }

        .tags {
            display: flex;
            flex-direction: row;
            justify-content: start;

            span {
                padding: 8px 8px;
                margin-right: 20px;
                border-radius: 100px;
                background-color: var(--light-orange-200);
            }
        }
    }
</style>
